@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(~bootstrap-vue/dist/bootstrap-vue.css);
@import url(~flatpickr/dist/flatpickr.css);
@import url(~@fortawesome/fontawesome-svg-core/styles.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #24baef;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #24baef;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0e8cb9;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c2ecfb;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8ddbf7;
}

.table-hover .table-primary:hover {
  background-color: #aae5f9;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #aae5f9;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #9bdff8;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(36, 186, 239, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 5px;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 5px;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(36, 186, 239, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #24baef;
  border-color: #24baef;
}

.btn-primary:hover {
  color: #fff;
  background-color: #10a7dd;
  border-color: #0f9ed1;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 196, 241, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #24baef;
  border-color: #24baef;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0f9ed1;
  border-color: #0e95c5;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 196, 241, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #24baef;
  border-color: #24baef;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #24baef;
  border-color: #24baef;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(36, 186, 239, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #24baef;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #24baef;
  border-color: #24baef;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(36, 186, 239, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #24baef;
  text-decoration: none;
}

.btn-link:hover {
  color: #0e8cb9;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #24baef;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #24baef;
  background-color: #24baef;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(36, 186, 239, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #9bdff8;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #caeefb;
  border-color: #caeefb;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 5px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #24baef;
  background-color: #24baef;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(36, 186, 239, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(36, 186, 239, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(36, 186, 239, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(36, 186, 239, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  appearance: none;
}

.custom-select:focus {
  border-color: #9bdff8;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(36, 186, 239, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #9bdff8;
  box-shadow: 0 0 0 0.2rem rgba(36, 186, 239, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 5px 5px 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(36, 186, 239, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(36, 186, 239, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(36, 186, 239, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #24baef;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #caeefb;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #24baef;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #caeefb;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #24baef;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #caeefb;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 5px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #24baef;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(5px - 1px) calc(5px - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(5px - 1px) calc(5px - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(5px - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(5px - 1px);
  border-top-right-radius: calc(5px - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(5px - 1px);
  border-bottom-left-radius: calc(5px - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 5px;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 5px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #24baef;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0e8cb9;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(36, 186, 239, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #24baef;
  border-color: #24baef;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #24baef;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0f9ed1;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(36, 186, 239, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #13617c;
  background-color: #d3f1fc;
  border-color: #c2ecfb;
}

.alert-primary hr {
  border-top-color: #aae5f9;
}

.alert-primary .alert-link {
  color: #0c3e50;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 5px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #24baef;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #24baef;
  border-color: #24baef;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #13617c;
  background-color: #c2ecfb;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #13617c;
  background-color: #aae5f9;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #13617c;
  border-color: #13617c;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 5px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #24baef !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0f9ed1 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #24baef !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 5px !important;
}

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #24baef !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0e8cb9 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

@keyframes swal2-show {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: .875em;
    width: 1.5625em;
  }
}

@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em;
  }
}

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -.375em;
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

body.swal2-toast-shown .swal2-container {
  background-color: transparent;
}

body.swal2-toast-shown .swal2-container.swal2-shown {
  background-color: transparent;
}

body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}

body.swal2-toast-shown .swal2-container.swal2-top-start, body.swal2-toast-shown .swal2-container.swal2-top-left {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

body.swal2-toast-shown .swal2-container.swal2-center-start, body.swal2-toast-shown .swal2-container.swal2-center-left {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}

body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-start, body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

body.swal2-toast-column .swal2-toast {
  flex-direction: column;
  align-items: stretch;
}

body.swal2-toast-column .swal2-toast .swal2-actions {
  flex: 1;
  align-self: stretch;
  height: 2.2em;
  margin-top: .3125em;
}

body.swal2-toast-column .swal2-toast .swal2-loading {
  justify-content: center;
}

body.swal2-toast-column .swal2-toast .swal2-input {
  height: 2em;
  margin: .3125em auto;
  font-size: 1em;
}

body.swal2-toast-column .swal2-toast .swal2-validation-message {
  font-size: 1em;
}

.swal2-popup.swal2-toast {
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: 0.625em;
  box-shadow: 0 0 0.625em #d9d9d9;
  overflow-y: hidden;
}

.swal2-popup.swal2-toast .swal2-header {
  flex-direction: row;
}

.swal2-popup.swal2-toast .swal2-title {
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 .6em;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-footer {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
  font-size: 0.8em;
}

.swal2-popup.swal2-toast .swal2-close {
  position: initial;
  width: 0.8em;
  height: 0.8em;
  line-height: 0.8;
}

.swal2-popup.swal2-toast .swal2-content {
  justify-content: flex-start;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-icon {
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0;
}

.swal2-popup.swal2-toast .swal2-icon-text {
  font-size: 2em;
  font-weight: bold;
  line-height: 1em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  top: .875em;
  width: 1.375em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
  left: .3125em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
  right: .3125em;
}

.swal2-popup.swal2-toast .swal2-actions {
  height: auto;
  margin: 0 .3125em;
}

.swal2-popup.swal2-toast .swal2-styled {
  margin: 0 .3125em;
  padding: .3125em .625em;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-styled:focus {
  box-shadow: 0 0 0 0.0625em #fff, 0 0 0 0.125em rgba(50, 100, 150, 0.4);
}

.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'] {
  position: absolute;
  width: 2em;
  height: 2.8125em;
  transform: rotate(45deg);
  border-radius: 50%;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'][class$='left'] {
  top: -.25em;
  left: -.9375em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'][class$='right'] {
  top: -.25em;
  left: .9375em;
  transform-origin: 0 2em;
  border-radius: 0 4em 4em 0;
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: .4375em;
  width: .4375em;
  height: 2.6875em;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'] {
  height: .3125em;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'][class$='tip'] {
  top: 1.125em;
  left: .1875em;
  width: .75em;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'][class$='long'] {
  top: .9375em;
  right: .1875em;
  width: 1.375em;
}

.swal2-popup.swal2-toast.swal2-show {
  animation: showSweetToast .5s;
}

.swal2-popup.swal2-toast.swal2-hide {
  animation: hideSweetToast .2s forwards;
}

.swal2-popup.swal2-toast .swal2-animate-success-icon .swal2-success-line-tip {
  animation: animate-toast-success-tip .75s;
}

.swal2-popup.swal2-toast .swal2-animate-success-icon .swal2-success-line-long {
  animation: animate-toast-success-long .75s;
}

@keyframes showSweetToast {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg);
    opacity: 0;
  }
  33% {
    transform: translateY(0) rotateZ(-2deg);
    opacity: .5;
  }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg);
    opacity: .7;
  }
  100% {
    transform: translateY(0) rotateZ(0);
    opacity: 1;
  }
}

@keyframes hideSweetToast {
  0% {
    opacity: 1;
  }
  33% {
    opacity: .5;
  }
  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}

@keyframes animate-toast-success-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0;
  }
  54% {
    top: .125em;
    left: .125em;
    width: 0;
  }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em;
  }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em;
  }
}

@keyframes animate-toast-success-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0;
  }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em;
  }
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}

body.swal2-height-auto {
  height: auto !important;
}

body.swal2-no-backdrop .swal2-shown {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  background-color: transparent;
}

body.swal2-no-backdrop .swal2-shown > .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

body.swal2-no-backdrop .swal2-shown.swal2-top {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

body.swal2-no-backdrop .swal2-shown.swal2-top-start, body.swal2-no-backdrop .swal2-shown.swal2-top-left {
  top: 0;
  left: 0;
}

body.swal2-no-backdrop .swal2-shown.swal2-top-end, body.swal2-no-backdrop .swal2-shown.swal2-top-right {
  top: 0;
  right: 0;
}

body.swal2-no-backdrop .swal2-shown.swal2-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body.swal2-no-backdrop .swal2-shown.swal2-center-start, body.swal2-no-backdrop .swal2-shown.swal2-center-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

body.swal2-no-backdrop .swal2-shown.swal2-center-end, body.swal2-no-backdrop .swal2-shown.swal2-center-right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

body.swal2-no-backdrop .swal2-shown.swal2-bottom {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

body.swal2-no-backdrop .swal2-shown.swal2-bottom-start, body.swal2-no-backdrop .swal2-shown.swal2-bottom-left {
  bottom: 0;
  left: 0;
}

body.swal2-no-backdrop .swal2-shown.swal2-bottom-end, body.swal2-no-backdrop .swal2-shown.swal2-bottom-right {
  right: 0;
  bottom: 0;
}

.swal2-container {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: transparent;
  z-index: 1060;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.swal2-container.swal2-top {
  align-items: flex-start;
}

.swal2-container.swal2-top-start, .swal2-container.swal2-top-left {
  align-items: flex-start;
  justify-content: flex-start;
}

.swal2-container.swal2-top-end, .swal2-container.swal2-top-right {
  align-items: flex-start;
  justify-content: flex-end;
}

.swal2-container.swal2-center {
  align-items: center;
}

.swal2-container.swal2-center-start, .swal2-container.swal2-center-left {
  align-items: center;
  justify-content: flex-start;
}

.swal2-container.swal2-center-end, .swal2-container.swal2-center-right {
  align-items: center;
  justify-content: flex-end;
}

.swal2-container.swal2-bottom {
  align-items: flex-end;
}

.swal2-container.swal2-bottom-start, .swal2-container.swal2-bottom-left {
  align-items: flex-end;
  justify-content: flex-start;
}

.swal2-container.swal2-bottom-end, .swal2-container.swal2-bottom-right {
  align-items: flex-end;
  justify-content: flex-end;
}

.swal2-container.swal2-grow-fullscreen > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-self: stretch;
  justify-content: center;
}

.swal2-container.swal2-grow-row > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}

.swal2-container.swal2-grow-column {
  flex: 1;
  flex-direction: column;
}

.swal2-container.swal2-grow-column.swal2-top, .swal2-container.swal2-grow-column.swal2-center, .swal2-container.swal2-grow-column.swal2-bottom {
  align-items: center;
}

.swal2-container.swal2-grow-column.swal2-top-start, .swal2-container.swal2-grow-column.swal2-center-start, .swal2-container.swal2-grow-column.swal2-bottom-start, .swal2-container.swal2-grow-column.swal2-top-left, .swal2-container.swal2-grow-column.swal2-center-left, .swal2-container.swal2-grow-column.swal2-bottom-left {
  align-items: flex-start;
}

.swal2-container.swal2-grow-column.swal2-top-end, .swal2-container.swal2-grow-column.swal2-center-end, .swal2-container.swal2-grow-column.swal2-bottom-end, .swal2-container.swal2-grow-column.swal2-top-right, .swal2-container.swal2-grow-column.swal2-center-right, .swal2-container.swal2-grow-column.swal2-bottom-right {
  align-items: flex-end;
}

.swal2-container.swal2-grow-column > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}

.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen) > .swal2-modal {
  margin: auto;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-container .swal2-modal {
    margin: 0 !important;
  }
}

.swal2-container.swal2-fade {
  transition: background-color .1s;
}

.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, 0.4);
}

.swal2-popup {
  display: none;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border-radius: 0.3125em;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
  box-sizing: border-box;
}

.swal2-popup:focus {
  outline: none;
}

.swal2-popup.swal2-loading {
  overflow-y: hidden;
}

.swal2-popup .swal2-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.swal2-popup .swal2-title {
  display: block;
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.swal2-popup .swal2-actions {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1.25em auto 0;
  z-index: 1;
}

.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4;
}

.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.swal2-popup .swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
  width: 2.5em;
  height: 2.5em;
  margin: .46875em;
  padding: 0;
  border: .25em solid transparent;
  border-radius: 100%;
  border-color: transparent;
  background-color: transparent !important;
  color: transparent;
  cursor: default;
  box-sizing: border-box;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  user-select: none;
}

.swal2-popup .swal2-actions.swal2-loading .swal2-styled.swal2-cancel {
  margin-right: 30px;
  margin-left: 30px;
}

.swal2-popup .swal2-actions.swal2-loading :not(.swal2-styled).swal2-confirm::after {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  border: 3px solid #999999;
  border-radius: 50%;
  border-right-color: transparent;
  box-shadow: 1px 1px 1px #fff;
  content: '';
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
}

.swal2-popup .swal2-styled {
  margin: .3125em;
  padding: .625em 2em;
  font-weight: 500;
  box-shadow: none;
}

.swal2-popup .swal2-styled:not([disabled]) {
  cursor: pointer;
}

.swal2-popup .swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #3085d6;
  color: #fff;
  font-size: 1.0625em;
}

.swal2-popup .swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #aaa;
  color: #fff;
  font-size: 1.0625em;
}

.swal2-popup .swal2-styled:focus {
  outline: none;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(50, 100, 150, 0.4);
}

.swal2-popup .swal2-styled::-moz-focus-inner {
  border: 0;
}

.swal2-popup .swal2-footer {
  justify-content: center;
  margin: 1.25em 0 0;
  padding: 1em 0 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em;
}

.swal2-popup .swal2-image {
  max-width: 100%;
  margin: 1.25em auto;
}

.swal2-popup .swal2-close {
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  padding: 0;
  transition: color 0.1s ease-out;
  border: none;
  border-radius: 0;
  outline: initial;
  background: transparent;
  color: #cccccc;
  font-family: serif;
  font-size: 2.5em;
  line-height: 1.2;
  cursor: pointer;
  overflow: hidden;
}

.swal2-popup .swal2-close:hover {
  transform: none;
  color: #f27474;
}

.swal2-popup > .swal2-input,
.swal2-popup > .swal2-file,
.swal2-popup > .swal2-textarea,
.swal2-popup > .swal2-select,
.swal2-popup > .swal2-radio,
.swal2-popup > .swal2-checkbox {
  display: none;
}

.swal2-popup .swal2-content {
  justify-content: center;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 1.125em;
  font-weight: 300;
  line-height: normal;
  z-index: 1;
  word-wrap: break-word;
}

.swal2-popup #swal2-content {
  text-align: center;
}

.swal2-popup .swal2-input,
.swal2-popup .swal2-file,
.swal2-popup .swal2-textarea,
.swal2-popup .swal2-select,
.swal2-popup .swal2-radio,
.swal2-popup .swal2-checkbox {
  margin: 1em auto;
}

.swal2-popup .swal2-input,
.swal2-popup .swal2-file,
.swal2-popup .swal2-textarea {
  width: 100%;
  transition: border-color .3s, box-shadow .3s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  font-size: 1.125em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
}

.swal2-popup .swal2-input.swal2-inputerror,
.swal2-popup .swal2-file.swal2-inputerror,
.swal2-popup .swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important;
}

.swal2-popup .swal2-input:focus,
.swal2-popup .swal2-file:focus,
.swal2-popup .swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: 0 0 3px #c4e6f5;
}

.swal2-popup .swal2-input::placeholder,
.swal2-popup .swal2-file::placeholder,
.swal2-popup .swal2-textarea::placeholder {
  color: #cccccc;
}

.swal2-popup .swal2-range input {
  width: 80%;
}

.swal2-popup .swal2-range output {
  width: 20%;
  font-weight: 600;
  text-align: center;
}

.swal2-popup .swal2-range input,
.swal2-popup .swal2-range output {
  height: 2.625em;
  margin: 1em auto;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em;
}

.swal2-popup .swal2-input {
  height: 2.625em;
  padding: 0 0.75em;
}

.swal2-popup .swal2-input[type='number'] {
  max-width: 10em;
}

.swal2-popup .swal2-file {
  font-size: 1.125em;
}

.swal2-popup .swal2-textarea {
  height: 6.75em;
  padding: 0.75em;
}

.swal2-popup .swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: .375em .625em;
  color: #545454;
  font-size: 1.125em;
}

.swal2-popup .swal2-radio,
.swal2-popup .swal2-checkbox {
  align-items: center;
  justify-content: center;
}

.swal2-popup .swal2-radio label,
.swal2-popup .swal2-checkbox label {
  margin: 0 .6em;
  font-size: 1.125em;
}

.swal2-popup .swal2-radio input,
.swal2-popup .swal2-checkbox input {
  margin: 0 .4em;
}

.swal2-popup .swal2-validation-message {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  background: #f0f0f0;
  color: #666666;
  font-size: 1em;
  font-weight: 300;
  overflow: hidden;
}

.swal2-popup .swal2-validation-message::before {
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 .625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
  content: '!';
  zoom: normal;
}

@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}

@-moz-document url-prefix() {
  .swal2-close:focus {
    outline: 2px solid rgba(50, 100, 150, 0.4);
  }
}

.swal2-icon {
  position: relative;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: .25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  box-sizing: content-box;
  user-select: none;
  zoom: normal;
}

.swal2-icon-text {
  font-size: 3.75em;
}

.swal2-icon.swal2-error {
  border-color: #f27474;
}

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: .3125em;
  border-radius: .125em;
  background-color: #f27474;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
  left: 1.0625em;
  transform: rotate(45deg);
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
  right: 1em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86;
}

.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee;
}

.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd;
}

.swal2-icon.swal2-success {
  border-color: #a5dc86;
}

.swal2-icon.swal2-success [class^='swal2-success-circular-line'] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
}

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='left'] {
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='right'] {
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  top: -.25em;
  left: -.25em;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
  z-index: 2;
  box-sizing: content-box;
}

.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  top: .5em;
  left: 1.625em;
  width: .4375em;
  height: 5.625em;
  transform: rotate(-45deg);
  z-index: 1;
}

.swal2-icon.swal2-success [class^='swal2-success-line'] {
  display: block;
  position: absolute;
  height: .3125em;
  border-radius: .125em;
  background-color: #a5dc86;
  z-index: 2;
}

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='tip'] {
  top: 2.875em;
  left: .875em;
  width: 1.5625em;
  transform: rotate(45deg);
}

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='long'] {
  top: 2.375em;
  right: .5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}

.swal2-progresssteps {
  align-items: center;
  margin: 0 0 1.25em;
  padding: 0;
  font-weight: 600;
}

.swal2-progresssteps li {
  display: inline-block;
  position: relative;
}

.swal2-progresssteps .swal2-progresscircle {
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #3085d6;
  color: #fff;
  line-height: 2em;
  text-align: center;
  z-index: 20;
}

.swal2-progresssteps .swal2-progresscircle:first-child {
  margin-left: 0;
}

.swal2-progresssteps .swal2-progresscircle:last-child {
  margin-right: 0;
}

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep {
  background: #3085d6;
}

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progresscircle {
  background: #add8e6;
}

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progressline {
  background: #add8e6;
}

.swal2-progresssteps .swal2-progressline {
  width: 2.5em;
  height: .4em;
  margin: 0 -1px;
  background: #3085d6;
  z-index: 10;
}

[class^='swal2'] {
  -webkit-tap-highlight-color: transparent;
}

.swal2-show {
  animation: swal2-show 0.3s;
}

.swal2-show.swal2-noanimation {
  animation: none;
}

.swal2-hide {
  animation: swal2-hide 0.15s forwards;
}

.swal2-hide.swal2-noanimation {
  animation: none;
}

.swal2-rtl .swal2-close {
  right: auto;
  left: 0;
}

.swal2-animate-success-icon .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip 0.75s;
}

.swal2-animate-success-icon .swal2-success-line-long {
  animation: swal2-animate-success-line-long 0.75s;
}

.swal2-animate-success-icon .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}

.swal2-animate-error-icon {
  animation: swal2-animate-error-icon 0.5s;
}

.swal2-animate-error-icon .swal2-x-mark {
  animation: swal2-animate-error-x-mark 0.5s;
}

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden='true'] {
    display: none;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: initial !important;
  }
}

.iti {
  position: relative;
  display: inline-block;
}

.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input, .iti input[type=text], .iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}

.iti__flag-box {
  display: inline-block;
  width: 20px;
}

.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}

.iti__country {
  padding: 5px 10px;
  outline: none;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
}

.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}

.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../../build/img/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../../build/img/flags@2x.png");
  }
}

.iti__flag.iti__np {
  background-color: transparent;
}

nav.navbar {
  justify-content: normal;
}

nav.navbar .main-navbar .navbar-nav  {
  flex-direction: row !important;
}

nav.navbar .nav-link {
  color: #fff !important;
}

nav.navbar .nav-link:hover, nav.navbar .nav-link.active {
  color: #24baef !important;
}

nav.navbar .navbar-toggler {
  color: #fff;
}

nav.navbar .navbar-toggler::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  margin-bottom: -2px;
}

nav.navbar .navbar-toggler.collapsed {
  color: #fff;
}

.navbar-nav.member-nav {
  margin-left: auto;
}

.navbar-nav.member-nav .search {
  margin-bottom: 0;
  align-items: center;
  margin-right: 20px;
  width: auto;
  cursor: pointer;
}

.navbar-nav.member-nav .search input {
  color: #fff;
  background-color: transparent;
  border: 0px solid rgba(206, 212, 218, 0.3);
  box-shadow: none;
  text-indent: -500px;
  width: 3px;
  padding: 0px;
  transition: all 0.5s ease;
  -webkit-appearance: none;
}

.navbar-nav.member-nav .search .input-group-append {
  cursor: pointer;
  margin-left: 0;
  box-shadow: none;
}

.navbar-nav.member-nav .search .input-group-append .input-group-text {
  box-shadow: none;
  background-color: #031522;
  background-color: transparent;
  border: 0px solid rgba(206, 212, 218, 0.3);
  transition: all 0.5s ease;
  color: #bfbaba;
}

.navbar-nav.member-nav .search .input-group-append .input-group-text svg {
  height: 1.5rem;
}

.navbar-nav.member-nav .search.active input {
  background-color: #031522;
  border-width: 1px 0px 1px 1px;
  padding: 0.375rem 0.75rem;
  width: 200px;
  text-indent: 0px;
}

.navbar-nav.member-nav .search.active .input-group-append {
  background-color: #031522;
}

.navbar-nav.member-nav .search.active .input-group-append .input-group-text {
  border-width: 1px 1px 1px 0px;
}

.navbar-nav.member-nav .discover {
  color: #24baef !important;
  padding-top: 11px;
  margin-right: 20px;
}

.navbar-nav.member-nav .user-dropdown {
  cursor: pointer;
  width: 66px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-nav.member-nav .user-dropdown #dropdown02 {
  display: flex;
  align-items: center;
}

.navbar-nav.member-nav .user-dropdown svg {
  fill: #24baef;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.navbar-nav.member-nav .user-dropdown .dropdown-menu {
  right: 4px;
  background-color: #051d2e;
  position: absolute !important;
}

.navbar-nav.member-nav .user-dropdown .dropdown-menu img.img-avatar {
  width: 34px;
  margin-right: 5px;
}

.navbar-nav.member-nav .user-dropdown .dropdown-menu .dropdown-item,
.navbar-nav.member-nav .user-dropdown .dropdown-menu .dropdown-header {
  font-size: 14px;
  padding: 0.25rem 1.0rem;
  color: #fff;
}

.navbar-nav.member-nav .user-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #141414;
}

.navbar-nav.member-nav .user-dropdown .dropdown-menu .dropdown-header {
  margin-bottom: 10px;
}

footer {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 100px;
  color: white;
  background-color: #051d2e;
}

footer a {
  color: white;
}

footer .claim {
  margin-bottom: 30px;
  padding-left: 0;
}

footer .claim img {
  vertical-align: bottom;
  margin-right: 20px;
}

footer .claim h4 {
  font-size: 20px;
  display: inline-block;
}

footer .footer-links {
  padding: 0;
  margin-bottom: 2rem;
}

footer .footer-links .cgu {
  margin-left: 0.5rem;
}

footer .footer-links li {
  display: inline-block;
  margin-right: .5rem;
}

footer .footer-copyrights,
footer .footer-copyrights-2 {
  padding: 0;
}

footer .footer-copyrights-2 {
  margin-bottom: 0px;
  font-size: 12px;
}

footer .follow-us {
  padding: 0;
}

footer .footer-locales {
  padding: 0;
}

footer .footer-locales a {
  text-decoration: none;
  text-transform: uppercase;
}

footer .footer-locales a strong {
  color: #24baef;
}

footer .footer-locales a span.separator {
  color: #fff;
  margin-right: 5px;
  margin-left: 2px;
}

footer .footer-locales a:hover {
  text-decoration: none;
}

footer .footer-locales a:hover span {
  color: #fff;
}

footer .footer-locales a:first-child:before {
  content: "";
}

.btn-facebook {
  color: #fff;
  background-color: #365899;
  border-color: #365899;
}

.btn-facebook:hover {
  color: #fff;
  background-color: #2c487d;
  border-color: #294273;
}

.btn-facebook:focus, .btn-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 113, 168, 0.5);
}

.btn-facebook.disabled, .btn-facebook:disabled {
  color: #fff;
  background-color: #365899;
  border-color: #365899;
}

.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #294273;
  border-color: #253d6a;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 113, 168, 0.5);
}

.btn-twitter {
  color: #fff;
  background-color: #1DA1F2;
  border-color: #1DA1F2;
}

.btn-twitter:hover {
  color: #fff;
  background-color: #0d8ddc;
  border-color: #0c85d0;
}

.btn-twitter:focus, .btn-twitter.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}

.btn-twitter.disabled, .btn-twitter:disabled {
  color: #fff;
  background-color: #1DA1F2;
  border-color: #1DA1F2;
}

.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #0c85d0;
  border-color: #0b7ec4;
}

.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}

.btn-google {
  color: #fff;
  background-color: #DB4437;
  border-color: #DB4437;
}

.btn-google:hover {
  color: #fff;
  background-color: #c83124;
  border-color: #bd2e22;
}

.btn-google:focus, .btn-google.focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 96, 85, 0.5);
}

.btn-google.disabled, .btn-google:disabled {
  color: #fff;
  background-color: #DB4437;
  border-color: #DB4437;
}

.btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active,
.show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #bd2e22;
  border-color: #b22c20;
}

.btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus,
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 96, 85, 0.5);
}

.btn-linkedin {
  color: #fff;
  background-color: #0077B5;
  border-color: #0077B5;
}

.btn-linkedin:hover {
  color: #fff;
  background-color: #005e8f;
  border-color: #005582;
}

.btn-linkedin:focus, .btn-linkedin.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 139, 192, 0.5);
}

.btn-linkedin.disabled, .btn-linkedin:disabled {
  color: #fff;
  background-color: #0077B5;
  border-color: #0077B5;
}

.btn-linkedin:not(:disabled):not(.disabled):active, .btn-linkedin:not(:disabled):not(.disabled).active,
.show > .btn-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #005582;
  border-color: #004d75;
}

.btn-linkedin:not(:disabled):not(.disabled):active:focus, .btn-linkedin:not(:disabled):not(.disabled).active:focus,
.show > .btn-linkedin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 139, 192, 0.5);
}

.btn-github {
  color: #fff;
  background-color: #24292E;
  border-color: #24292E;
}

.btn-github:hover {
  color: #fff;
  background-color: #131619;
  border-color: #0e1011;
}

.btn-github:focus, .btn-github.focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 73, 77, 0.5);
}

.btn-github.disabled, .btn-github:disabled {
  color: #fff;
  background-color: #24292E;
  border-color: #24292E;
}

.btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #0e1011;
  border-color: #08090a;
}

.btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 73, 77, 0.5);
}

.btn-bitbucket {
  color: #fff;
  background-color: #253858;
  border-color: #253858;
}

.btn-bitbucket:hover {
  color: #fff;
  background-color: #1a273d;
  border-color: #162134;
}

.btn-bitbucket:focus, .btn-bitbucket.focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 86, 113, 0.5);
}

.btn-bitbucket.disabled, .btn-bitbucket:disabled {
  color: #fff;
  background-color: #253858;
  border-color: #253858;
}

.btn-bitbucket:not(:disabled):not(.disabled):active, .btn-bitbucket:not(:disabled):not(.disabled).active,
.show > .btn-bitbucket.dropdown-toggle {
  color: #fff;
  background-color: #162134;
  border-color: #121b2b;
}

.btn-bitbucket:not(:disabled):not(.disabled):active:focus, .btn-bitbucket:not(:disabled):not(.disabled).active:focus,
.show > .btn-bitbucket.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 86, 113, 0.5);
}

.page-blog .article {
  background-color: #e9ecef;
  height: 100%;
}

.page-blog .article .article__cover {
  position: relative;
  display: block;
  overflow: hidden;
  height: 250px;
}

.page-blog .article .article__cover img {
  display: block;
  max-width: 100%;
  height: auto;
}

.page-blog .article .article__cover:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.page-blog .article .article__infos {
  padding: 1rem;
}

.page-blog .article .article__title, .page-blog .article .article__published, .page-blog .article .article__summary {
  margin: 0;
}

.page-blog .article .article__published {
  margin: 1rem 0;
  font-size: .75rem;
}

.page-post .cover {
  position: relative;
  overflow: hidden;
  height: 400px;
}

.page-post .cover:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.page-post .img-fluid {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.page-post .post-title {
  position: absolute;
  color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.page-post .post-title h1 {
  border: solid 0.2rem #fff;
  border-left: 0;
  border-right: 0;
}

*[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

*[dir="rtl"] body {
  text-align: right;
}

*[dir="rtl"] .nav {
  padding-right: 0;
}

*[dir="rtl"] .navbar-nav .nav-item {
  float: right;
}

*[dir="rtl"] .navbar-nav .nav-item + .nav-item {
  margin-right: 1rem;
  margin-left: inherit;
}

*[dir="rtl"] th {
  text-align: right;
}

*[dir="rtl"] .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 4rem;
}

*[dir="rtl"] .dropdown-menu {
  right: 0;
  left: auto !important;
  text-align: right;
}

*[dir="rtl"] .checkbox label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

*[dir="rtl"] .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 5px 5px 0;
}

*[dir="rtl"] .btn-group > .btn:last-child:not(:first-child),
*[dir="rtl"] .btn-group > .dropdown-toggle:not(:first-child) {
  border-radius: 5px 0 0 5px;
}

*[dir="rtl"] .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-radius: 5px 0 0 5px;
}

*[dir="rtl"] .custom-control-label::after,
*[dir="rtl"] .custom-control-label::before {
  right: 0;
  left: inherit;
}

*[dir="rtl"] .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat left 0.75rem center;
  background-size: 8px 10px;
}

*[dir="rtl"] .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
*[dir="rtl"] .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
*[dir="rtl"] .input-group > .input-group-append:not(:last-child) > .btn,
*[dir="rtl"] .input-group > .input-group-append:not(:last-child) > .input-group-text,
*[dir="rtl"] .input-group > .input-group-prepend > .btn,
*[dir="rtl"] .input-group > .input-group-prepend > .input-group-text {
  border-radius: 0 5px 5px 0;
}

*[dir="rtl"] .input-group > .input-group-append > .btn,
*[dir="rtl"] .input-group > .input-group-append > .input-group-text,
*[dir="rtl"] .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
*[dir="rtl"] .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
*[dir="rtl"] .input-group > .input-group-prepend:not(:first-child) > .btn,
*[dir="rtl"] .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-radius: 5px 0 0 5px;
}

*[dir="rtl"] .input-group > .custom-select:not(:first-child),
*[dir="rtl"] .input-group > .form-control:not(:first-child) {
  border-radius: 5px 0 0 5px;
}

*[dir="rtl"] .input-group > .custom-select:not(:last-child),
*[dir="rtl"] .input-group > .form-control:not(:last-child) {
  border-radius: 0 5px 5px 0;
}

*[dir="rtl"] .input-group > .custom-select:not(:last-child):not(:first-child),
*[dir="rtl"] .input-group > .form-control:not(:last-child):not(:first-child) {
  border-radius: 0;
}

*[dir="rtl"] .custom-control {
  padding-right: 1.5rem;
  padding-left: inherit;
  margin-right: inherit;
  margin-left: 1rem;
}

*[dir="rtl"] .custom-control-indicator {
  right: 0;
  left: inherit;
}

*[dir="rtl"] .custom-file-label::after {
  right: initial;
  left: -1px;
  border-radius: .25rem 0 0 .25rem;
}

*[dir="rtl"] .radio input,
*[dir="rtl"] .radio-inline,
*[dir="rtl"] .checkbox input,
*[dir="rtl"] .checkbox-inline input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

*[dir="rtl"] .list-group {
  padding-right: 0;
  padding-left: 40px;
}

*[dir="rtl"] .close {
  float: left;
}

*[dir="rtl"] .modal-header .close {
  margin: -15px auto -15px -15px;
}

*[dir="rtl"] .modal-footer > :not(:first-child) {
  margin-right: .25rem;
}

*[dir="rtl"] .alert-dismissible .close {
  right: inherit;
  left: 0;
}

*[dir="rtl"] .dropdown-toggle::after {
  margin-right: .255em;
  margin-left: 0;
}

*[dir="rtl"] .form-check-input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

*[dir="rtl"] .form-check-label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

*[dir="rtl"] .offset-1 {
  margin-right: 8.33333%;
  margin-left: 0;
}

*[dir="rtl"] .offset-2 {
  margin-right: 16.66667%;
  margin-left: 0;
}

*[dir="rtl"] .offset-3 {
  margin-right: 25%;
  margin-left: 0;
}

*[dir="rtl"] .offset-4 {
  margin-right: 33.33333%;
  margin-left: 0;
}

*[dir="rtl"] .offset-5 {
  margin-right: 41.66667%;
  margin-left: 0;
}

*[dir="rtl"] .offset-6 {
  margin-right: 50%;
  margin-left: 0;
}

*[dir="rtl"] .offset-7 {
  margin-right: 58.33333%;
  margin-left: 0;
}

*[dir="rtl"] .offset-8 {
  margin-right: 66.66667%;
  margin-left: 0;
}

*[dir="rtl"] .offset-9 {
  margin-right: 75%;
  margin-left: 0;
}

*[dir="rtl"] .offset-10 {
  margin-right: 83.33333%;
  margin-left: 0;
}

*[dir="rtl"] .offset-11 {
  margin-right: 91.66667%;
  margin-left: 0;
}

@media (min-width: 576px) {
  *[dir="rtl"] .offset-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-sm-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-sm-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-sm-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-sm-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-sm-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-sm-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-sm-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-sm-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-sm-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-sm-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-sm-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  *[dir="rtl"] .offset-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-md-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-md-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-md-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-md-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-md-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-md-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-md-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-md-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-md-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-md-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-md-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  *[dir="rtl"] .offset-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-lg-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-lg-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-lg-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-lg-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-lg-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-lg-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-lg-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-lg-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-lg-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-lg-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-lg-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  *[dir="rtl"] .offset-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-xl-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-xl-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-xl-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-xl-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-xl-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-xl-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-xl-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-xl-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-xl-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-xl-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }
  *[dir="rtl"] .offset-xl-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

*[dir="rtl"] .mr-0,
*[dir="rtl"] .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

*[dir="rtl"] .ml-0,
*[dir="rtl"] .mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

*[dir="rtl"] .mr-1,
*[dir="rtl"] .mx-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}

*[dir="rtl"] .ml-1,
*[dir="rtl"] .mx-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}

*[dir="rtl"] .mr-2,
*[dir="rtl"] .mx-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

*[dir="rtl"] .ml-2,
*[dir="rtl"] .mx-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}

*[dir="rtl"] .mr-3,
*[dir="rtl"] .mx-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

*[dir="rtl"] .ml-3,
*[dir="rtl"] .mx-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}

*[dir="rtl"] .mr-4,
*[dir="rtl"] .mx-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}

*[dir="rtl"] .ml-4,
*[dir="rtl"] .mx-4 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important;
}

*[dir="rtl"] .mr-5,
*[dir="rtl"] .mx-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important;
}

*[dir="rtl"] .ml-5,
*[dir="rtl"] .mx-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important;
}

*[dir="rtl"] .pr-0,
*[dir="rtl"] .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

*[dir="rtl"] .pl-0,
*[dir="rtl"] .px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

*[dir="rtl"] .pr-1,
*[dir="rtl"] .px-1 {
  padding-right: 0 !important;
  padding-left: 0.25rem !important;
}

*[dir="rtl"] .pl-1,
*[dir="rtl"] .px-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
}

*[dir="rtl"] .pr-2,
*[dir="rtl"] .px-2 {
  padding-right: 0 !important;
  padding-left: 0.5rem !important;
}

*[dir="rtl"] .pl-2,
*[dir="rtl"] .px-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}

*[dir="rtl"] .pr-3,
*[dir="rtl"] .px-3 {
  padding-right: 0 !important;
  padding-left: 1rem !important;
}

*[dir="rtl"] .pl-3,
*[dir="rtl"] .px-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}

*[dir="rtl"] .pr-4,
*[dir="rtl"] .px-4 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important;
}

*[dir="rtl"] .pl-4,
*[dir="rtl"] .px-4 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}

*[dir="rtl"] .pr-5,
*[dir="rtl"] .px-5 {
  padding-right: 0 !important;
  padding-left: 3rem !important;
}

*[dir="rtl"] .pl-5,
*[dir="rtl"] .px-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important;
}

*[dir="rtl"] .mr-auto,
*[dir="rtl"] .mx-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

*[dir="rtl"] .ml-auto,
*[dir="rtl"] .mx-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  *[dir="rtl"] .mr-sm-0,
  *[dir="rtl"] .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  *[dir="rtl"] .ml-sm-0,
  *[dir="rtl"] .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  *[dir="rtl"] .mr-sm-1,
  *[dir="rtl"] .mx-sm-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  *[dir="rtl"] .ml-sm-1,
  *[dir="rtl"] .mx-sm-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  *[dir="rtl"] .mr-sm-2,
  *[dir="rtl"] .mx-sm-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  *[dir="rtl"] .ml-sm-2,
  *[dir="rtl"] .mx-sm-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  *[dir="rtl"] .mr-sm-3,
  *[dir="rtl"] .mx-sm-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  *[dir="rtl"] .ml-sm-3,
  *[dir="rtl"] .mx-sm-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  *[dir="rtl"] .mr-sm-4,
  *[dir="rtl"] .mx-sm-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  *[dir="rtl"] .ml-sm-4,
  *[dir="rtl"] .mx-sm-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  *[dir="rtl"] .mr-sm-5,
  *[dir="rtl"] .mx-sm-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  *[dir="rtl"] .ml-sm-5,
  *[dir="rtl"] .mx-sm-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  *[dir="rtl"] .pr-sm-0,
  *[dir="rtl"] .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  *[dir="rtl"] .pl-sm-0,
  *[dir="rtl"] .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  *[dir="rtl"] .pr-sm-1,
  *[dir="rtl"] .px-sm-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  *[dir="rtl"] .pl-sm-1,
  *[dir="rtl"] .px-sm-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  *[dir="rtl"] .pr-sm-2,
  *[dir="rtl"] .px-sm-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  *[dir="rtl"] .pl-sm-2,
  *[dir="rtl"] .px-sm-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  *[dir="rtl"] .pr-sm-3,
  *[dir="rtl"] .px-sm-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  *[dir="rtl"] .pl-sm-3,
  *[dir="rtl"] .px-sm-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  *[dir="rtl"] .pr-sm-4,
  *[dir="rtl"] .px-sm-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  *[dir="rtl"] .pl-sm-4,
  *[dir="rtl"] .px-sm-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  *[dir="rtl"] .pr-sm-5,
  *[dir="rtl"] .px-sm-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  *[dir="rtl"] .pl-sm-5,
  *[dir="rtl"] .px-sm-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  *[dir="rtl"] .mr-sm-auto,
  *[dir="rtl"] .mx-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  *[dir="rtl"] .ml-sm-auto,
  *[dir="rtl"] .mx-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 768px) {
  *[dir="rtl"] .mr-md-0,
  *[dir="rtl"] .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  *[dir="rtl"] .ml-md-0,
  *[dir="rtl"] .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  *[dir="rtl"] .mr-md-1,
  *[dir="rtl"] .mx-md-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  *[dir="rtl"] .ml-md-1,
  *[dir="rtl"] .mx-md-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  *[dir="rtl"] .mr-md-2,
  *[dir="rtl"] .mx-md-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  *[dir="rtl"] .ml-md-2,
  *[dir="rtl"] .mx-md-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  *[dir="rtl"] .mr-md-3,
  *[dir="rtl"] .mx-md-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  *[dir="rtl"] .ml-md-3,
  *[dir="rtl"] .mx-md-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  *[dir="rtl"] .mr-md-4,
  *[dir="rtl"] .mx-md-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  *[dir="rtl"] .ml-md-4,
  *[dir="rtl"] .mx-md-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  *[dir="rtl"] .mr-md-5,
  *[dir="rtl"] .mx-md-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  *[dir="rtl"] .ml-md-5,
  *[dir="rtl"] .mx-md-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  *[dir="rtl"] .pr-md-0,
  *[dir="rtl"] .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  *[dir="rtl"] .pl-md-0,
  *[dir="rtl"] .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  *[dir="rtl"] .pr-md-1,
  *[dir="rtl"] .px-md-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  *[dir="rtl"] .pl-md-1,
  *[dir="rtl"] .px-md-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  *[dir="rtl"] .pr-md-2,
  *[dir="rtl"] .px-md-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  *[dir="rtl"] .pl-md-2,
  *[dir="rtl"] .px-md-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  *[dir="rtl"] .pr-md-3,
  *[dir="rtl"] .px-md-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  *[dir="rtl"] .pl-md-3,
  *[dir="rtl"] .px-md-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  *[dir="rtl"] .pr-md-4,
  *[dir="rtl"] .px-md-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  *[dir="rtl"] .pl-md-4,
  *[dir="rtl"] .px-md-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  *[dir="rtl"] .pr-md-5,
  *[dir="rtl"] .px-md-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  *[dir="rtl"] .pl-md-5,
  *[dir="rtl"] .px-md-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  *[dir="rtl"] .mr-md-auto,
  *[dir="rtl"] .mx-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  *[dir="rtl"] .ml-md-auto,
  *[dir="rtl"] .mx-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 992px) {
  *[dir="rtl"] .mr-lg-0,
  *[dir="rtl"] .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  *[dir="rtl"] .ml-lg-0,
  *[dir="rtl"] .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  *[dir="rtl"] .mr-lg-1,
  *[dir="rtl"] .mx-lg-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  *[dir="rtl"] .ml-lg-1,
  *[dir="rtl"] .mx-lg-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  *[dir="rtl"] .mr-lg-2,
  *[dir="rtl"] .mx-lg-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  *[dir="rtl"] .ml-lg-2,
  *[dir="rtl"] .mx-lg-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  *[dir="rtl"] .mr-lg-3,
  *[dir="rtl"] .mx-lg-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  *[dir="rtl"] .ml-lg-3,
  *[dir="rtl"] .mx-lg-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  *[dir="rtl"] .mr-lg-4,
  *[dir="rtl"] .mx-lg-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  *[dir="rtl"] .ml-lg-4,
  *[dir="rtl"] .mx-lg-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  *[dir="rtl"] .mr-lg-5,
  *[dir="rtl"] .mx-lg-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  *[dir="rtl"] .ml-lg-5,
  *[dir="rtl"] .mx-lg-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  *[dir="rtl"] .pr-lg-0,
  *[dir="rtl"] .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  *[dir="rtl"] .pl-lg-0,
  *[dir="rtl"] .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  *[dir="rtl"] .pr-lg-1,
  *[dir="rtl"] .px-lg-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  *[dir="rtl"] .pl-lg-1,
  *[dir="rtl"] .px-lg-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  *[dir="rtl"] .pr-lg-2,
  *[dir="rtl"] .px-lg-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  *[dir="rtl"] .pl-lg-2,
  *[dir="rtl"] .px-lg-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  *[dir="rtl"] .pr-lg-3,
  *[dir="rtl"] .px-lg-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  *[dir="rtl"] .pl-lg-3,
  *[dir="rtl"] .px-lg-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  *[dir="rtl"] .pr-lg-4,
  *[dir="rtl"] .px-lg-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  *[dir="rtl"] .pl-lg-4,
  *[dir="rtl"] .px-lg-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  *[dir="rtl"] .pr-lg-5,
  *[dir="rtl"] .px-lg-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  *[dir="rtl"] .pl-lg-5,
  *[dir="rtl"] .px-lg-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  *[dir="rtl"] .mr-lg-auto,
  *[dir="rtl"] .mx-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  *[dir="rtl"] .ml-lg-auto,
  *[dir="rtl"] .mx-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  *[dir="rtl"] .mr-xl-0,
  *[dir="rtl"] .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  *[dir="rtl"] .ml-xl-0,
  *[dir="rtl"] .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  *[dir="rtl"] .mr-xl-1,
  *[dir="rtl"] .mx-xl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  *[dir="rtl"] .ml-xl-1,
  *[dir="rtl"] .mx-xl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  *[dir="rtl"] .mr-xl-2,
  *[dir="rtl"] .mx-xl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  *[dir="rtl"] .ml-xl-2,
  *[dir="rtl"] .mx-xl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  *[dir="rtl"] .mr-xl-3,
  *[dir="rtl"] .mx-xl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  *[dir="rtl"] .ml-xl-3,
  *[dir="rtl"] .mx-xl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  *[dir="rtl"] .mr-xl-4,
  *[dir="rtl"] .mx-xl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  *[dir="rtl"] .ml-xl-4,
  *[dir="rtl"] .mx-xl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  *[dir="rtl"] .mr-xl-5,
  *[dir="rtl"] .mx-xl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  *[dir="rtl"] .ml-xl-5,
  *[dir="rtl"] .mx-xl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  *[dir="rtl"] .pr-xl-0,
  *[dir="rtl"] .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  *[dir="rtl"] .pl-xl-0,
  *[dir="rtl"] .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  *[dir="rtl"] .pr-xl-1,
  *[dir="rtl"] .px-xl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  *[dir="rtl"] .pl-xl-1,
  *[dir="rtl"] .px-xl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  *[dir="rtl"] .pr-xl-2,
  *[dir="rtl"] .px-xl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  *[dir="rtl"] .pl-xl-2,
  *[dir="rtl"] .px-xl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  *[dir="rtl"] .pr-xl-3,
  *[dir="rtl"] .px-xl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  *[dir="rtl"] .pl-xl-3,
  *[dir="rtl"] .px-xl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  *[dir="rtl"] .pr-xl-4,
  *[dir="rtl"] .px-xl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  *[dir="rtl"] .pl-xl-4,
  *[dir="rtl"] .px-xl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  *[dir="rtl"] .pr-xl-5,
  *[dir="rtl"] .px-xl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  *[dir="rtl"] .pl-xl-5,
  *[dir="rtl"] .px-xl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  *[dir="rtl"] .mr-xl-auto,
  *[dir="rtl"] .mx-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  *[dir="rtl"] .ml-xl-auto,
  *[dir="rtl"] .mx-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}

*[dir="rtl"] .float-right {
  float: left !important;
}

*[dir="rtl"] .float-left {
  float: right !important;
}

*[dir="rtl"] .navbar-brand {
  margin-right: inherit !important;
  margin-left: 1rem !important;
}

@font-face {
  font-family: "feather";
  src: url("../../../fonts/feather/feather-webfont.eot?t=1501841394106");
  /* IE9*/
  src: url("../../../fonts/feather/feather-webfont.eot?t=1501841394106#iefix") format("embedded-opentype"), url("../../../fonts/feather/feather-webfont.woff?t=1501841394106") format("woff"), url("../../../fonts/feather/feather-webfont.ttf?t=1501841394106") format("truetype"), url("../../../fonts/feather/feather-webfont.svg?t=1501841394106#feather") format("svg");
  /* iOS 4.1- */
}

.fe {
  font-family: 'feather' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fe-activity:before {
  content: "\e900";
}

.fe-airplay:before {
  content: "\e901";
}

.fe-alert-circle:before {
  content: "\e902";
}

.fe-alert-octagon:before {
  content: "\e903";
}

.fe-alert-triangle:before {
  content: "\e904";
}

.fe-align-center:before {
  content: "\e905";
}

.fe-align-justify:before {
  content: "\e906";
}

.fe-align-left:before {
  content: "\e907";
}

.fe-align-right:before {
  content: "\e908";
}

.fe-anchor:before {
  content: "\e909";
}

.fe-aperture:before {
  content: "\e90a";
}

.fe-arrow-down:before {
  content: "\e90b";
}

.fe-arrow-down-circle:before {
  content: "\e90c";
}

.fe-arrow-down-left:before {
  content: "\e90d";
}

.fe-arrow-down-right:before {
  content: "\e90e";
}

.fe-arrow-left:before {
  content: "\e90f";
}

.fe-arrow-left-circle:before {
  content: "\e910";
}

.fe-arrow-right:before {
  content: "\e911";
}

.fe-arrow-right-circle:before {
  content: "\e912";
}

.fe-arrow-up:before {
  content: "\e913";
}

.fe-arrow-up-circle:before {
  content: "\e914";
}

.fe-arrow-up-left:before {
  content: "\e915";
}

.fe-arrow-up-right:before {
  content: "\e916";
}

.fe-at-sign:before {
  content: "\e917";
}

.fe-award:before {
  content: "\e918";
}

.fe-bar-chart:before {
  content: "\e919";
}

.fe-bar-chart-2:before {
  content: "\e91a";
}

.fe-battery:before {
  content: "\e91b";
}

.fe-battery-charging:before {
  content: "\e91c";
}

.fe-bell:before {
  content: "\e91d";
}

.fe-bell-off:before {
  content: "\e91e";
}

.fe-bluetooth:before {
  content: "\e91f";
}

.fe-bold:before {
  content: "\e920";
}

.fe-book:before {
  content: "\e921";
}

.fe-book-open:before {
  content: "\e922";
}

.fe-bookmark:before {
  content: "\e923";
}

.fe-box:before {
  content: "\e924";
}

.fe-briefcase:before {
  content: "\e925";
}

.fe-calendar:before {
  content: "\e926";
}

.fe-camera:before {
  content: "\e927";
}

.fe-camera-off:before {
  content: "\e928";
}

.fe-cast:before {
  content: "\e929";
}

.fe-check:before {
  content: "\e92a";
}

.fe-check-circle:before {
  content: "\e92b";
}

.fe-check-square:before {
  content: "\e92c";
}

.fe-chevron-down:before {
  content: "\e92d";
}

.fe-chevron-left:before {
  content: "\e92e";
}

.fe-chevron-right:before {
  content: "\e92f";
}

.fe-chevron-up:before {
  content: "\e930";
}

.fe-chevrons-down:before {
  content: "\e931";
}

.fe-chevrons-left:before {
  content: "\e932";
}

.fe-chevrons-right:before {
  content: "\e933";
}

.fe-chevrons-up:before {
  content: "\e934";
}

.fe-chrome:before {
  content: "\e935";
}

.fe-circle:before {
  content: "\e936";
}

.fe-clipboard:before {
  content: "\e937";
}

.fe-clock:before {
  content: "\e938";
}

.fe-cloud:before {
  content: "\e939";
}

.fe-cloud-drizzle:before {
  content: "\e93a";
}

.fe-cloud-lightning:before {
  content: "\e93b";
}

.fe-cloud-off:before {
  content: "\e93c";
}

.fe-cloud-rain:before {
  content: "\e93d";
}

.fe-cloud-snow:before {
  content: "\e93e";
}

.fe-code:before {
  content: "\e93f";
}

.fe-codepen:before {
  content: "\e940";
}

.fe-command:before {
  content: "\e941";
}

.fe-compass:before {
  content: "\e942";
}

.fe-copy:before {
  content: "\e943";
}

.fe-corner-down-left:before {
  content: "\e944";
}

.fe-corner-down-right:before {
  content: "\e945";
}

.fe-corner-left-down:before {
  content: "\e946";
}

.fe-corner-left-up:before {
  content: "\e947";
}

.fe-corner-right-down:before {
  content: "\e948";
}

.fe-corner-right-up:before {
  content: "\e949";
}

.fe-corner-up-left:before {
  content: "\e94a";
}

.fe-corner-up-right:before {
  content: "\e94b";
}

.fe-cpu:before {
  content: "\e94c";
}

.fe-credit-card:before {
  content: "\e94d";
}

.fe-crop:before {
  content: "\e94e";
}

.fe-crosshair:before {
  content: "\e94f";
}

.fe-database:before {
  content: "\e950";
}

.fe-delete:before {
  content: "\e951";
}

.fe-disc:before {
  content: "\e952";
}

.fe-dollar-sign:before {
  content: "\e953";
}

.fe-download:before {
  content: "\e954";
}

.fe-download-cloud:before {
  content: "\e955";
}

.fe-droplet:before {
  content: "\e956";
}

.fe-edit:before {
  content: "\e957";
}

.fe-edit-2:before {
  content: "\e958";
}

.fe-edit-3:before {
  content: "\e959";
}

.fe-external-link:before {
  content: "\e95a";
}

.fe-eye:before {
  content: "\e95b";
}

.fe-eye-off:before {
  content: "\e95c";
}

.fe-facebook:before {
  content: "\e95d";
}

.fe-fast-forward:before {
  content: "\e95e";
}

.fe-feather:before {
  content: "\e95f";
}

.fe-file:before {
  content: "\e960";
}

.fe-file-minus:before {
  content: "\e961";
}

.fe-file-plus:before {
  content: "\e962";
}

.fe-file-text:before {
  content: "\e963";
}

.fe-film:before {
  content: "\e964";
}

.fe-filter:before {
  content: "\e965";
}

.fe-flag:before {
  content: "\e966";
}

.fe-folder:before {
  content: "\e967";
}

.fe-folder-minus:before {
  content: "\e968";
}

.fe-folder-plus:before {
  content: "\e969";
}

.fe-git-branch:before {
  content: "\e96a";
}

.fe-git-commit:before {
  content: "\e96b";
}

.fe-git-merge:before {
  content: "\e96c";
}

.fe-git-pull-request:before {
  content: "\e96d";
}

.fe-github:before {
  content: "\e96e";
}

.fe-gitlab:before {
  content: "\e96f";
}

.fe-globe:before {
  content: "\e970";
}

.fe-grid:before {
  content: "\e971";
}

.fe-hard-drive:before {
  content: "\e972";
}

.fe-hash:before {
  content: "\e973";
}

.fe-headphones:before {
  content: "\e974";
}

.fe-heart:before {
  content: "\e975";
}

.fe-help-circle:before {
  content: "\e976";
}

.fe-home:before {
  content: "\e977";
}

.fe-image:before {
  content: "\e978";
}

.fe-inbox:before {
  content: "\e979";
}

.fe-info:before {
  content: "\e97a";
}

.fe-instagram:before {
  content: "\e97b";
}

.fe-italic:before {
  content: "\e97c";
}

.fe-layers:before {
  content: "\e97d";
}

.fe-layout:before {
  content: "\e97e";
}

.fe-life-buoy:before {
  content: "\e97f";
}

.fe-link:before {
  content: "\e980";
}

.fe-link-2:before {
  content: "\e981";
}

.fe-linkedin:before {
  content: "\e982";
}

.fe-list:before {
  content: "\e983";
}

.fe-loader:before {
  content: "\e984";
}

.fe-lock:before {
  content: "\e985";
}

.fe-log-in:before {
  content: "\e986";
}

.fe-log-out:before {
  content: "\e987";
}

.fe-mail:before {
  content: "\e988";
}

.fe-map:before {
  content: "\e989";
}

.fe-map-pin:before {
  content: "\e98a";
}

.fe-maximize:before {
  content: "\e98b";
}

.fe-maximize-2:before {
  content: "\e98c";
}

.fe-menu:before {
  content: "\e98d";
}

.fe-message-circle:before {
  content: "\e98e";
}

.fe-message-square:before {
  content: "\e98f";
}

.fe-mic:before {
  content: "\e990";
}

.fe-mic-off:before {
  content: "\e991";
}

.fe-minimize:before {
  content: "\e992";
}

.fe-minimize-2:before {
  content: "\e993";
}

.fe-minus:before {
  content: "\e994";
}

.fe-minus-circle:before {
  content: "\e995";
}

.fe-minus-square:before {
  content: "\e996";
}

.fe-monitor:before {
  content: "\e997";
}

.fe-moon:before {
  content: "\e998";
}

.fe-more-horizontal:before {
  content: "\e999";
}

.fe-more-vertical:before {
  content: "\e99a";
}

.fe-move:before {
  content: "\e99b";
}

.fe-music:before {
  content: "\e99c";
}

.fe-navigation:before {
  content: "\e99d";
}

.fe-navigation-2:before {
  content: "\e99e";
}

.fe-octagon:before {
  content: "\e99f";
}

.fe-package:before {
  content: "\e9a0";
}

.fe-paperclip:before {
  content: "\e9a1";
}

.fe-pause:before {
  content: "\e9a2";
}

.fe-pause-circle:before {
  content: "\e9a3";
}

.fe-percent:before {
  content: "\e9a4";
}

.fe-phone:before {
  content: "\e9a5";
}

.fe-phone-call:before {
  content: "\e9a6";
}

.fe-phone-forwarded:before {
  content: "\e9a7";
}

.fe-phone-incoming:before {
  content: "\e9a8";
}

.fe-phone-missed:before {
  content: "\e9a9";
}

.fe-phone-off:before {
  content: "\e9aa";
}

.fe-phone-outgoing:before {
  content: "\e9ab";
}

.fe-pie-chart:before {
  content: "\e9ac";
}

.fe-play:before {
  content: "\e9ad";
}

.fe-play-circle:before {
  content: "\e9ae";
}

.fe-plus:before {
  content: "\e9af";
}

.fe-plus-circle:before {
  content: "\e9b0";
}

.fe-plus-square:before {
  content: "\e9b1";
}

.fe-pocket:before {
  content: "\e9b2";
}

.fe-power:before {
  content: "\e9b3";
}

.fe-printer:before {
  content: "\e9b4";
}

.fe-radio:before {
  content: "\e9b5";
}

.fe-refresh-ccw:before {
  content: "\e9b6";
}

.fe-refresh-cw:before {
  content: "\e9b7";
}

.fe-repeat:before {
  content: "\e9b8";
}

.fe-rewind:before {
  content: "\e9b9";
}

.fe-rotate-ccw:before {
  content: "\e9ba";
}

.fe-rotate-cw:before {
  content: "\e9bb";
}

.fe-rss:before {
  content: "\e9bc";
}

.fe-save:before {
  content: "\e9bd";
}

.fe-scissors:before {
  content: "\e9be";
}

.fe-search:before {
  content: "\e9bf";
}

.fe-send:before {
  content: "\e9c0";
}

.fe-server:before {
  content: "\e9c1";
}

.fe-settings:before {
  content: "\e9c2";
}

.fe-share:before {
  content: "\e9c3";
}

.fe-share-2:before {
  content: "\e9c4";
}

.fe-shield:before {
  content: "\e9c5";
}

.fe-shield-off:before {
  content: "\e9c6";
}

.fe-shopping-bag:before {
  content: "\e9c7";
}

.fe-shopping-cart:before {
  content: "\e9c8";
}

.fe-shuffle:before {
  content: "\e9c9";
}

.fe-sidebar:before {
  content: "\e9ca";
}

.fe-skip-back:before {
  content: "\e9cb";
}

.fe-skip-forward:before {
  content: "\e9cc";
}

.fe-slack:before {
  content: "\e9cd";
}

.fe-slash:before {
  content: "\e9ce";
}

.fe-sliders:before {
  content: "\e9cf";
}

.fe-smartphone:before {
  content: "\e9d0";
}

.fe-speaker:before {
  content: "\e9d1";
}

.fe-square:before {
  content: "\e9d2";
}

.fe-star:before {
  content: "\e9d3";
}

.fe-stop-circle:before {
  content: "\e9d4";
}

.fe-sun:before {
  content: "\e9d5";
}

.fe-sunrise:before {
  content: "\e9d6";
}

.fe-sunset:before {
  content: "\e9d7";
}

.fe-tablet:before {
  content: "\e9d8";
}

.fe-tag:before {
  content: "\e9d9";
}

.fe-target:before {
  content: "\e9da";
}

.fe-terminal:before {
  content: "\e9db";
}

.fe-thermometer:before {
  content: "\e9dc";
}

.fe-thumbs-down:before {
  content: "\e9dd";
}

.fe-thumbs-up:before {
  content: "\e9de";
}

.fe-toggle-left:before {
  content: "\e9df";
}

.fe-toggle-right:before {
  content: "\e9e0";
}

.fe-trash:before {
  content: "\e9e1";
}

.fe-trash-2:before {
  content: "\e9e2";
}

.fe-trending-down:before {
  content: "\e9e3";
}

.fe-trending-up:before {
  content: "\e9e4";
}

.fe-triangle:before {
  content: "\e9e5";
}

.fe-truck:before {
  content: "\e9e6";
}

.fe-tv:before {
  content: "\e9e7";
}

.fe-twitter:before {
  content: "\e9e8";
}

.fe-type:before {
  content: "\e9e9";
}

.fe-umbrella:before {
  content: "\e9ea";
}

.fe-underline:before {
  content: "\e9eb";
}

.fe-unlock:before {
  content: "\e9ec";
}

.fe-upload:before {
  content: "\e9ed";
}

.fe-upload-cloud:before {
  content: "\e9ee";
}

.fe-user:before {
  content: "\e9ef";
}

.fe-user-check:before {
  content: "\e9f0";
}

.fe-user-minus:before {
  content: "\e9f1";
}

.fe-user-plus:before {
  content: "\e9f2";
}

.fe-user-x:before {
  content: "\e9f3";
}

.fe-users:before {
  content: "\e9f4";
}

.fe-video:before {
  content: "\e9f5";
}

.fe-video-off:before {
  content: "\e9f6";
}

.fe-voicemail:before {
  content: "\e9f7";
}

.fe-volume:before {
  content: "\e9f8";
}

.fe-volume-1:before {
  content: "\e9f9";
}

.fe-volume-2:before {
  content: "\e9fa";
}

.fe-volume-x:before {
  content: "\e9fb";
}

.fe-watch:before {
  content: "\e9fc";
}

.fe-wifi:before {
  content: "\e9fd";
}

.fe-wifi-off:before {
  content: "\e9fe";
}

.fe-wind:before {
  content: "\e9ff";
}

.fe-x:before {
  content: "\ea00";
}

.fe-x-circle:before {
  content: "\ea01";
}

.fe-x-square:before {
  content: "\ea02";
}

.fe-zap:before {
  content: "\ea03";
}

.fe-zap-off:before {
  content: "\ea04";
}

.fe-zoom-in:before {
  content: "\ea05";
}

.fe-zoom-out:before {
  content: "\ea06";
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/source-sans-pro-v11-latin-regular.eot?t=1501841394106");
  src: url("../../fonts/source-sans-pro-v11-latin-regular.eot?t=1501841394106#iefix") format("embedded-opentype"), url("../../fonts/source-sans-pro-v11-latin-regular.woff?t=1501841394106") format("woff"), url("../../fonts/source-sans-pro-v11-latin-regular.ttf?t=1501841394106") format("truetype"), url("../../fonts/source-sans-pro-v11-latin-regular.svg?t=1501841394106#feather") format("svg");
  font-style: normal;
}

@font-face {
  font-family: "Material Icons";
  src: url("../../fonts/material-icons/MaterialIcons-Regular.eot");
  src: url("../../fonts/material-icons/MaterialIcons-Regular.eot?t=1501841394106#iefix") format("embedded-opentype"), url("../../fonts/material-icons/MaterialIcons-Regular.woff?t=1501841394106") format("woff"), url("../../fonts/material-icons/MaterialIcons-Regular.ttf?t=1501841394106") format("truetype"), url("../../fonts/material-icons/MaterialIcons-Regular.svg?t=1501841394106#feather") format("svg");
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  color: #000;
  font-family: "Source Sans Pro";
  width: 100%;
  min-height: 100%;
}

body .content {
  min-height: 60vh;
}

.form-control.flatpickr-input {
  background-color: #fff;
}

.form-control.flatpickr-alt-input {
  background-color: #fff;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.custom-inline-feedback {
  display: inline !important;
}

.cat-row {
  margin-bottom: 80px;
}

.cat-row > h2 {
  font-size: 23px;
  margin-bottom: 10px;
  margin-left: 4vw;
  position: relative;
  overflow: hidden;
  font-weight: 600;
}

.cat-row > h2:after {
  content: ' ';
  border-bottom: 1px solid #fff;
  width: 100%;
  display: inline-block;
  position: absolute;
  bottom: 5px;
  margin-left: 5px;
}

.navbar.bg-dark {
  background-color: rgba(15, 31, 43, 0.83) !important;
}

.navbar.bg-dark .container {
  max-width: 1900px;
}

.navbar.bg-dark .navbar-brand img {
  vertical-align: sub;
}

.navbar.bg-dark .navbar-brand span {
  margin-left: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.highlight {
  position: relative;
}

.jumbotron {
  margin-bottom: 0;
  direction: ltr !important;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 2rem 1rem;
  }
}

.breadcrumb {
  padding: 1rem 0;
  background-color: transparent;
}

.breadcrumb-item.active {
  color: #fff;
}

[v-cloak] {
  display: none;
}

.intl-tel-input {
  width: 100%;
}

iframe {
  width: 100%;
  border: 0;
}

.alert.alert-top {
  border: none;
  margin-bottom: 0;
}

.custom-control-indicator, .custom-control-description, .switch-label, .switch-handle {
  margin-top: .25rem;
}

[data-toggle="slider"]:not(.slick-initialized) {
  visibility: hidden;
}

.wysiwyg-content .image.image-style-align-left {
  float: left;
  margin-right: 2em;
}

.wysiwyg-content .image.image-style-align-right {
  float: right;
  margin-left: 2em;
}

.wysiwyg-content .image.image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}

.wysiwyg-content .image.image-style-align-center, .wysiwyg-content .image .image-style-align-left, .wysiwyg-content .image .image-style-align-right {
  max-width: 50%;
}

.wysiwyg-content .image > img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.wysiwyg-content .image > figcaption {
  text-align: center;
  color: #333;
  background-color: #f7f7f7;
  padding: .8em;
  font-size: .75em;
  outline-offset: -1px;
}

.white-page {
  background-color: #fff;
  color: #000;
}

.login-page,
.register-page {
  background-position: center;
  background-size: cover;
}

.login-page .register-title h2,
.register-page .register-title h2 {
  margin-bottom: 40px;
  font-weight: bold;
}

.login-page .register-title h2 span,
.register-page .register-title h2 span {
  color: #24baef;
}

.login-page .register-title h3,
.register-page .register-title h3 {
  margin-bottom: 60px;
  font-size: 24px;
}

.login-page .register-terms,
.register-page .register-terms {
  margin-top: 60px;
  font-size: 16px;
}

.login-page .connect-with,
.register-page .connect-with {
  margin-top: 30px;
  margin-bottom: 40px;
}

.login-page .connect-with h6,
.register-page .connect-with h6 {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-weight: bold;
}

.login-page .connect-with a.btn,
.register-page .connect-with a.btn {
  transform: scale(1.4);
}

@media (max-width: 575.98px) {
  .login-page .g-recaptcha,
  .register-page .g-recaptcha {
    transform: scale(0.82);
    transform-origin: 0;
  }
}

.login-page .card,
.register-page .card {
  background-color: rgba(255, 255, 255, 0.85);
}

.login-page .card .custom-control-label::before,
.register-page .card .custom-control-label::before {
  background-color: #c0c3c5;
}

.login-page .card .progress,
.register-page .card .progress {
  margin-top: 4px;
}

/****** User Pages *********/
.page-user .collapse-toogle {
  padding-left: 10px;
  background-color: #343a40;
  color: #fff;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
}

.page-user .collapse-toogle svg {
  position: absolute;
  right: 10px;
  top: 4px;
}

/****** End User Pages *********/
/****** Checkout Page *********/
.checkout .help-text {
  font-size: 12px;
  color: #fff;
}

.checkout .help-text a {
  color: #fff;
}

.checkout .help-text a:hover {
  text-decoration: underline;
}

.checkout .card {
  cursor: pointer;
  background-color: white;
}

.checkout .card .card-header {
  background-color: white;
}

.checkout .card.active, .checkout .card.hover {
  background-color: #24baef;
}

.checkout .card.active .card-header, .checkout .card.hover .card-header {
  background-color: #24baef;
}

.checkout .card.active .pricing-card-title, .checkout .card.hover .pricing-card-title {
  color: #fff;
}

.checkout .card.active .card-body, .checkout .card.hover .card-body {
  color: #fff;
}

.checkout .card.not-hover {
  background-color: white;
}

.checkout .card.not-hover .card-header {
  background-color: white;
}

.checkout .card.not-hover .pricing-card-title {
  color: #000;
}

.checkout .card.not-hover .card-body {
  color: #000;
}

.checkout .card .card-header {
  text-transform: uppercase;
}

.checkout .card .card-header h4 {
  text-transform: uppercase;
  font-weight: bold !important;
  font-size: 26px;
  letter-spacing: 0.5px;
}

.checkout .card .card-body {
  font-size: 12px;
  font-weight: bold;
}

.checkout .card .card-body .pricing-card-title {
  font-size: 84px;
}

.checkout .card .card-body .pricing-card-title em {
  font-size: 32px;
  font-weight: bold;
  vertical-align: super;
}

.checkout .card .card-body .pricing-card-title .month {
  color: #000 !important;
  font-size: 22px;
  margin-left: -50px;
  font-weight: bold;
}

.checkout .card.plan_2 .card-body .pricing-card-title .month {
  color: #000 !important;
  font-size: 22px;
  margin-left: -70px;
  font-weight: bold;
}

.checkout .card.plan_3 .card-body .pricing-card-title {
  font-size: 54px;
}

.checkout #selected_plan {
  text-transform: capitalize;
}

.checkout .free-desc {
  font-size: 11px;
}

.checkout .loading-spinner {
  width: 60px;
  height: 60px;
  margin: 0 auto;
}

/******** STRIPE **********/
.spacer {
  margin-bottom: 24px;
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  background-color: white;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #ccd0d2;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#card-errors {
  color: #fa755a;
}

/****** MSTEPPER OVERRIDES *********/
ul.stepper.horizontal::before {
  box-shadow: none;
}

ul.stepper.horizontal .step {
  list-style: none;
}

ul.stepper.horizontal .step::before {
  background-color: transparent;
  border: 1px solid #fff;
  height: 31px;
  width: 31px;
  line-height: 28px;
  font-size: 16px;
}

ul.stepper.horizontal .step:not(:last-of-type)::after {
  background-color: rgba(255, 255, 255, 0.5);
  left: 14.5px;
}

ul.stepper.horizontal .step.active .step-title {
  color: #24baef !important;
}

ul.stepper.horizontal .step.active .step-title::before {
  color: #24baef !important;
  border-color: #24baef !important;
}

ul.stepper.horizontal .step.wrong .step-title::before {
  content: "\e645";
  font-size: 16px;
  font-family: 'Material Icons';
  background-color: red;
  line-height: 30px;
  text-align: center;
  width: 32px;
}

ul.stepper.horizontal .step .step-title {
  position: relative !important;
}

ul.stepper.horizontal .step .step-title::after {
  color: #fff !important;
}

ul.stepper.horizontal .step .step-title::before {
  background-color: transparent !important;
  border: 1px solid #fff;
  height: 32px;
  width: 32px;
  line-height: 28px;
  font-size: 16px;
  margin-top: -2px;
}

ul.stepper.horizontal .step .step-content {
  padding-bottom: 0px;
}

/* DARK MODE */
.card {
  color: #000;
}

.card img {
  max-width: 100%;
}

/****************** Responsive *********************/
/* header */
@media (max-width: 575.98px) {
  nav.navbar .navbar-toggler {
    border: 0px solid transparent;
    font-size: 15px;
    padding: 0;
  }
  nav.navbar .navbar-collapse.main-navbar {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    bottom: -100vh;
    background-color: rgba(0, 0, 0, 0.7);
  }
  nav.navbar .navbar-collapse.main-navbar .navbar-nav {
    padding-bottom: 5px;
    padding-top: 5px;
    flex-direction: column !important;
    background-color: black;
  }
  nav.navbar .navbar-collapse.main-navbar .navbar-nav .nav-link {
    padding-left: 15px;
    font-size: 19px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: #fff;
    cursor: pointer;
  }
  nav.navbar .navbar-nav.member-nav {
    position: absolute;
    right: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  nav.navbar .navbar-nav.member-nav .search {
    margin-right: 5px;
  }
  nav.navbar .navbar-nav.member-nav .search.active input {
    width: 190px;
  }
}

/* Footer */
@media (max-width: 575.98px) {
  footer {
    padding: 20px;
  }
  footer .claim h4 {
    font-size: 14px;
  }
  footer .claim img {
    width: 40px;
    margin-right: 10px;
    height: auto;
  }
  footer .follow-us .text-lg-right {
    margin-top: 10px;
  }
  footer .follow-us .text-lg-right a {
    margin-right: 10px;
  }
  footer .follow-us .text-lg-right a svg {
    width: 34px !important;
    height: 34px;
  }
  footer .footer-links {
    font-size: 14px;
  }
}

/*** PAGES ***/
/* Home */
.touch-device .cat-row {
  overflow: hidden;
}

.touch-device .carousel-container {
  margin-bottom: -17px;
  overflow: hidden;
}

.touch-device .carousel-container .slide-button {
  visibility: hidden;
}

.touch-device .carousel-container .slider-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.touch-device .carousel-container .slider-wrapper .slider {
  padding-right: 8vw;
}

@media (max-width: 767.98px) {
  .staytune .row {
    margin: 0;
  }
  .staytune .row h2 {
    font-size: 26px;
  }
}

/* Watch */
@media (max-width: 767.98px) {
  .watch-container .video-container .back-button {
    top: 20px;
    left: 20px;
  }
  .watch-container .video-container .back-button svg {
    width: 40px;
  }
  .watch-container .video-container .infos-button {
    top: 20px;
    right: 20px;
  }
  .watch-container .video-container .infos-button svg {
    width: 40px;
  }
  .watch-container .video-overlay {
    padding-top: 80px;
  }
  .watch-container .video-overlay .content {
    height: auto;
  }
  .watch-container .video-overlay .content .video-infos.center {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    padding: 30px;
  }
  .watch-container .video-overlay .content .video-infos.center h1.title {
    font-size: 32px;
  }
}
