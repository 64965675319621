
// Slick
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
$slick-arrow-color: black;
$slick-dot-color: black;

// intlTelInput
$flagsImagePath: "../../build/img/" !default;

// Bootstrap overrides
$border-radius: 5px;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

$theme-colors: (
  "primary": #24baef
);
