footer {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 100px;
  color: white;
  background-color: #051d2e;
  a {
    color: white;
  }
  .claim {
    margin-bottom: 30px;
    padding-left: 0;
    img {
      vertical-align: bottom;
      margin-right: 20px;
    }
    h4 {
      font-size: 20px;
      display: inline-block;
    }
  }
  .footer-links {
    padding: 0;
    margin-bottom: 2rem;
    .cgu {
      margin-left: 0.5rem;
    }
  }
  .footer-links li {
    display: inline-block;
    margin-right: .5rem;
  }
  .footer-copyrights,
  .footer-copyrights-2 {
    padding: 0;
  }
  .footer-copyrights-2 {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .follow-us {
    padding: 0;
  }
  .footer-locales {
    padding: 0;
    a {
      text-decoration: none;
      text-transform: uppercase;
      strong {
        color: #24baef;
      }
      span.separator {
        color: #fff;
        margin-right:5px;
        margin-left:2px;
      }
      &:hover {
        text-decoration: none;
        span {
          color: #fff;
        }
      }
      &:first-child {
        &:before {
          content: "";
        }
      }
    }
  }
}
