// BootstrapVue custom SCSS variables

// --- Tables ---
$b-table-busy-opacity: 0.55 !default;
$b-table-sort-icon-null: "\2195" !default; // Up-Down arrow
$b-table-sort-icon-ascending: "\2193" !default; // Down arrow
$b-table-sort-icon-descending: "\2191" !default; // Up arrow
$b-table-stacked-heading-width: 40% !default;
$b-table-stacked-gap: 1rem !default;

// --- Toasts ---
$b-toaster-zindex: 1100 !default;
$b-toaster-offset-top: 0.5rem !default;
$b-toaster-offset-bottom: $b-toaster-offset-top !default;
$b-toaster-offset-left: $b-toaster-offset-top !default;
$b-toaster-offset-right: $b-toaster-offset-top !default;

$b-toast-bg-level: $alert-bg-level !default;
$b-toast-border-level: $alert-border-level !default;
$b-toast-color-level: $alert-color-level !default;
