nav.navbar {
  justify-content: normal;
  .main-navbar{
    .navbar-nav {
      flex-direction: row !important;
    }
  }
  .nav-link {
    color: #fff !important;
    &:hover,
    &.active {
      color:#24baef !important
    }
  }
  .navbar-toggler {
    color:#fff;
    &::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        margin-bottom: -2px;
    }
    &.collapsed {
      color:#fff;
    }

  }
}
.navbar-nav.member-nav{
  margin-left: auto;
  .search {
    margin-bottom: 0;
    align-items: center;
    margin-right: 20px;
    width: auto;
    cursor: pointer;
    input {
      color:#fff;
      background-color: transparent;
      border: 0px solid rgba(206, 212, 218, 0.3);
      box-shadow: none;
      text-indent: -500px;
      width: 3px;
      padding: 0px;
      transition: all 0.5s ease;
      -webkit-appearance: none;
    }
    .input-group-append {
      cursor: pointer;
      margin-left: 0;
      box-shadow: none;
      .input-group-text {
        box-shadow: none;
        background-color: #031522;
        background-color: transparent;
        border: 0px solid rgba(206, 212, 218, 0.3);
        transition: all 0.5s ease;
        color: #bfbaba;
        svg {
          height: 1.5rem;
        }
      }
    }
    &.active {
      input {
        background-color: #031522;
        border-width: 1px 0px 1px 1px;
        padding: 0.375rem 0.75rem;
        width: 200px;
        text-indent: 0px;
      }
      .input-group-append {
        background-color: #031522;
        .input-group-text {
          border-width: 1px 1px 1px 0px;
        }
      }
    }
  }
  .discover {
    color: #24baef !important;
    padding-top: 11px;
    margin-right: 20px;
  }
  .user-dropdown{
    cursor:pointer;
    width: 66px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    #dropdown02{
      display: flex;
      align-items: center;
    }
    svg {
      fill: #24baef;
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
    .dropdown-menu {
      right: 4px;
      background-color: #051d2e;
      position: absolute !important;
      img.img-avatar {
        width: 34px;
        margin-right: 5px;
      }
      .dropdown-item,
      .dropdown-header {
        font-size: 14px;
        padding: 0.25rem 1.0rem;
        color:#fff;
      }
      .dropdown-item {
        &:hover{
          background-color: #141414;
        }
      }
      .dropdown-header{
        margin-bottom: 10px;
      }
    }
  }
}
